import React, { useEffect, useState } from "react";

// typings

// components
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import Swal from "sweetalert2";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import CompanyOperativeForm from "~/components/Operatives/CompanyOperativeForm";
import NurseOperativeForm from "~/components/Operatives/NurseOperativeForm";
import OperativeForm from "~/components/Operatives/OperativeForm";
import PaymentOperativeForm from "~/components/Operatives/PaymentOperativeForm";
import appointmentService from "~/utils/api/v1/appointmentService";
import nurseService from "~/utils/api/v1/nurseService";
import { OperativeAppointmentData } from "~/utils/interfaces/Appointment";
import { NurseName } from "~/utils/interfaces/Nurse";

const EditContainer = styled(Paper)`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .MuiButtonBase-root {
    float: right;
    margin: 20px 10px;
  }
`;

interface OperativeEditProps {
  id: string;
}

const OperativeEdit = ({ id }: OperativeEditProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [nurses, setNurses] = useState<NurseName[]>([]);
  const [selectedNurses, setSelectedNurses] = useState<string[]>([]);
  const [operativeData, setOperativeData] = useState<OperativeAppointmentData>();
  const [nursesPayments, setNursesPayments] = useState<Record<string, { payment: number }>>({});

  const getOperativeData = async () => {
    setLoading(true);
    try {
      const req = await appointmentService.getOperativeData(id);
      setOperativeData(req.data.data);
      setSelectedNurses(req.data.data.appointment_nurses.map((n) => n.nurse.id));
      setNursesPayments(
        req.data.data.appointment_nurses.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.nurse.id]: { payment: curr.gross_ht_payment },
          }),
          {},
        ),
      );
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchNurses = async () => {
    setLoading(true);
    try {
      const req = await nurseService.fetchActiveNurses();
      setNurses(req.data.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOperativeData();
    fetchNurses();
  }, []);

  const submitButton = async (): Promise<void> => {
    setLoading(true);
    try {
      const data = {
        ...operativeData,
        nurses: nursesPayments,
      };
      await appointmentService.updateOperativeData(id, data);
      await Swal.fire({
        icon: "success",
        title: "Operativo modificado exitosamente",
        confirmButtonText: "Continuar",
        didClose: () => {
          const params = new URLSearchParams(location.search);
          const return_url = params.get("return_url");
          if (return_url && return_url != "") {
            navigate(return_url);
          } else {
            navigate(`/dashboard/`);
          }
        },
      });
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
        text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(
          err.response.data,
        )}`,
        confirmButtonText: "Continuar",
      });
    }
    setLoading(false);
  };

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <EditContainer variant="outlined">
          <h1>Editar operativo</h1>
          <OperativeForm
            operativeData={operativeData}
            setOperativeData={setOperativeData}
          />
          <CompanyOperativeForm
            operativeData={operativeData}
            setOperativeData={setOperativeData}
          />
          <PaymentOperativeForm
            operativeData={operativeData}
            setOperativeData={setOperativeData}
          />
          <NurseOperativeForm
            operativeData={operativeData}
            nurses={nurses}
            selectedNurses={selectedNurses}
            setSelectedNurses={setSelectedNurses}
            nursesPayments={nursesPayments}
            setNursesPayments={setNursesPayments}
          />
          <ButtonContainer>
            <Link to={`/appointment/${operativeData?.appointment}`}>
              <Button
                variant="text"
                color="primary"
              >
                Volver
              </Button>
            </Link>
            <Button
              variant="contained"
              onClick={submitButton}
              color="primary"
            >
              Guardar
            </Button>
          </ButtonContainer>
        </EditContainer>
      )}
    </PrivateRoute>
  );
};

export default OperativeEdit;
