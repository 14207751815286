import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  .MuiFormControl-root {
    margin: 0.5rem 0;
  }
`;

export default Wrapper;
