import React, { ChangeEvent, Dispatch, SetStateAction } from "react";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

import { OperativeAppointmentData } from "~/utils/interfaces/Appointment";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;

  .MuiFormControl-root {
    margin: 0.5rem 2rem;
  }
`;

interface OperativeFormProps {
  operativeData?: OperativeAppointmentData;
  setOperativeData: Dispatch<SetStateAction<OperativeAppointmentData | undefined>>;
}

const OperativeForm = ({ operativeData, setOperativeData }: OperativeFormProps): JSX.Element => {
  const formChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!operativeData) {
      return;
    }
    const newValue = event.target.value;
    const attribute = event.target.id;
    setOperativeData({ ...operativeData, [attribute]: newValue });
  };

  const leadChange = (event): void => {
    if (!operativeData) {
      return;
    }
    setOperativeData({ ...operativeData, lead: event.target.value });
  };

  return (
    <React.Fragment>
      {operativeData && (
        <>
          <h4>Datos operativo</h4>
          <Row>
            <TextField
              id="name"
              onChange={formChange}
              value={operativeData.name}
              helperText="Nombre"
              fullWidth
            />
            <TextField
              id="base_price"
              onChange={formChange}
              value={operativeData.base_price}
              helperText="Precio base"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="person_price"
              onChange={formChange}
              value={operativeData.person_price}
              helperText="Precio por persona"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
            />
            <FormControl fullWidth>
              <Select
                onChange={leadChange}
                value={operativeData.lead}
                fullWidth
              >
                <MenuItem value="formulario">Formulario</MenuItem>
                <MenuItem value="partner">Partner</MenuItem>
                <MenuItem value="intercom">Intercom</MenuItem>
              </Select>
              <FormHelperText>Lead</FormHelperText>
            </FormControl>
          </Row>
        </>
      )}
    </React.Fragment>
  );
};

export default OperativeForm;
