import React, { ChangeEvent, Dispatch, SetStateAction } from "react";

import { FormControl, FormHelperText, MenuItem, Select } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

import { OperativeAppointmentData } from "~/utils/interfaces/Appointment";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;

  .MuiFormControl-root {
    margin: 0.5rem 2rem;
  }
`;

interface PaymentOperativeFormProps {
  operativeData?: OperativeAppointmentData;
  setOperativeData: Dispatch<SetStateAction<OperativeAppointmentData | undefined>>;
}

const PaymentOperativeForm = ({ operativeData, setOperativeData }: PaymentOperativeFormProps): JSX.Element => {
  const formChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!operativeData) {
      return;
    }
    const newValue = event.target.value;
    const attribute = event.target.id;
    setOperativeData({
      ...operativeData,
      payment: { ...operativeData.payment, [attribute]: newValue },
    });
  };
  const statusChange = (event): void => {
    if (!operativeData) {
      return;
    }
    setOperativeData({
      ...operativeData,
      payment: {
        ...operativeData.payment,
        status: operativeData.payment.status,
      },
    });
  };

  return (
    <React.Fragment>
      {operativeData && (
        <React.Fragment>
          <h4>Datos pago</h4>
          <Row>
            <TextField
              id="amount"
              onChange={formChange}
              value={operativeData.payment.amount}
              helperText="Monto"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
            />
            <TextField
              id="gateway_identifier"
              onChange={formChange}
              value={operativeData.payment.gateway_identifier}
              helperText="Número de documento (pago)"
              fullWidth
            />
          </Row>
          <Row>
            <FormControl>
              <Select
                onChange={statusChange}
                value={operativeData.payment.status}
                fullWidth
              >
                <MenuItem value="Payment Pending">Pendiente</MenuItem>
                <MenuItem value="Invoice Sent">Factura Enviada</MenuItem>
                <MenuItem value="Payment Successful">Pagado</MenuItem>
              </Select>
              <FormHelperText>Estado del pago</FormHelperText>
            </FormControl>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PaymentOperativeForm;
