import React, { Dispatch, SetStateAction } from "react";

//typings

// components
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import Wrapper from "~/components/Appointments/Offline/Wrapper";
import Flex from "~/components/Containers/Flex";
import { SRemoveIcon } from "~/components/icons/SRemoveIcon";
import { OperativeAppointmentData } from "~/utils/interfaces/Appointment";
import { NurseName } from "~/utils/interfaces/Nurse";

interface NurseOperativeFormProps {
  operativeData?: OperativeAppointmentData;
  nurses: NurseName[];
  selectedNurses: string[];
  setSelectedNurses: Dispatch<SetStateAction<string[]>>;
  nursesPayments: Record<string, { payment: number }>;
  setNursesPayments: Dispatch<SetStateAction<Record<string, { payment: number }>>>;
}

const NurseOperativeForm = ({
  operativeData,
  selectedNurses,
  setSelectedNurses,
  nurses,
  nursesPayments,
  setNursesPayments,
}: NurseOperativeFormProps): JSX.Element => {
  const removeNurse = (removeNurseId: string) => {
    const newSelectedNurses = selectedNurses.filter((nurseId: string) => nurseId !== removeNurseId);
    setSelectedNurses(newSelectedNurses);
    const newNursesPayments = { ...nursesPayments };
    delete newNursesPayments[removeNurseId];
    setNursesPayments(newNursesPayments);
  };

  const getNursesRenderedNames = (selectedIds: string[] | string | any) => {
    return nurses
      .filter((nurse) => selectedIds.includes(nurse.id))
      .map((nurse) => `${nurse.names} ${nurse.last_names}`)
      .join(", ");
  };

  const handleNurseChange = (e: React.ChangeEvent<any>) => {
    setSelectedNurses(typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value);
  };

  return (
    <React.Fragment>
      {operativeData && (
        <>
          <h4>Datos HT</h4>
          <Flex
            direction="column"
            padding="1rem 2rem"
          >
            <Flex
              direction="column"
              style={{ marginTop: "1rem" }}
            >
              <Wrapper>
                <h5>Nombre HT</h5>
                <h5>Pago Bruto</h5>
              </Wrapper>
              {selectedNurses.map((selected) => (
                <Wrapper
                  style={{ margin: "0.25rem" }}
                  key={selected}
                >
                  <Flex direction="row">
                    <SRemoveIcon onClick={() => removeNurse(selected)} />
                    <span
                      style={{
                        margin: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {getNursesRenderedNames(selected)}
                    </span>
                  </Flex>
                  <TextField
                    value={nursesPayments[selected]?.payment}
                    onChange={(e) =>
                      setNursesPayments({
                        ...nursesPayments,
                        [selected]: { payment: parseInt(e.target.value) },
                      })
                    }
                    type="number"
                    style={{ margin: 0 }}
                  />
                </Wrapper>
              ))}
            </Flex>
            <FormControl>
              <Select
                value={selectedNurses}
                onChange={handleNurseChange}
                multiple={true}
                renderValue={getNursesRenderedNames}
                style={{ maxWidth: "30rem" }}
              >
                {nurses.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >
                    {" "}
                    {item.names} {item.last_names}{" "}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Tomador</FormHelperText>
            </FormControl>
          </Flex>
        </>
      )}
    </React.Fragment>
  );
};

export default NurseOperativeForm;
